.contact-page{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.contact-img{
  width: 250px;
  margin-bottom: 15px;
}
.contact-box{
  text-align: start;
}
@media (max-width:1022px){
  .contact-box{
    text-align: center;
  }
}