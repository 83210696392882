.hero-seccion-semillas{
  background-image: url('../../assets/Semillas/pioneer-fondo.jpg');
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.logo-seccion-semillas{
  width: 50%;
}
@media (max-width:1022px){
  .logo-seccion-semillas{
    width: 100%;
  }
}