.hero-seccion-servicios{
  background-image: url('../../assets/Taller/servicio-fondo.jpg');
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-size: auto;
}
.titulo-hero-servicios{
  font-size: 200px;
  line-height: 75%;
  margin-bottom: 50px;
  color:var(--gris);
}
@media (max-width: 572px){
  .titulo-hero-servicios{
    font-size: 100px;
  }
}