.hero-seccion-pauny{
  background-image: url('../../assets/tractores/pauny-hero.jpg');
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  background-position: left;
  background-size: cover;
  overflow: hidden;
}
.logo-seccion{
  width: 75%;
}
@media (max-width:1022px){
  .logo-seccion{
    width: 150%;
  }
}