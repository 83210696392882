.marcas{
  margin-bottom: 50px;
}
.contenedor{
  z-index: 0;
  position: relative;
  width: 100%;
  height: 70vh;
  background-color: var(--verde);
  background-position: center;
  background-size: cover;
  transition: width 1.2s;
  overflow: hidden;
  text-decoration: none;
}
.marca-titulo{
  position: inherit;
  margin-bottom: -1000px;
  transition: margin-bottom 1s ease-in-out;
  font-weight: bold;
}
.fondo-1{
  background-image: url('../../assets/tractores/pauny.jpg');
}
.fondo-2{
  background-image: url('../../assets/Semillas/pioner.jpeg');
}
.fondo-3{
  background-image: url('../../assets/MaquinaStihl/stihl.jpg');
}
.fondo-4{
  background-image: url('../../assets/Taller/taller.jpg');
}
.fondo-5{
  background-image: url('../../assets/Tromen/tromen.jpg');
}
.color-1, .color-2, .color-3, .color-4, .color-5{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.8s;
}
.color-1{
  background-color: var(--amarillo);
}
.color-2{
  background-color: var(--verde);
}
.color-3{
  background-color: var(--naranja);
}
.color-4{
  background-color: var(--gris);
}
.color-5{
  background-color: var(--rojo);
}
.fondo-1:hover,.fondo-2:hover,.fondo-3:hover, .fondo-4:hover, .fondo-5:hover{
  width: 600%;
}
.fondo-1:hover .color-1, .fondo-2:hover .color-2, .fondo-3:hover .color-3, .fondo-4:hover .color-4, .fondo-4:hover, .fondo-5:hover .color-5{
  opacity: 0.95;
}
.fondo-1:hover .marca-titulo, .fondo-2:hover .marca-titulo, .fondo-3:hover .marca-titulo, .fondo-4:hover .marca-titulo, .fondo-5:hover .marca-titulo{
  margin-bottom: 0;
}
.semillas{
  color: transparent;
}
.maquinaria{
  color: transparent;
}
.fondo-1:hover .maquinaria, .fondo-3:hover .maquinaria{
  color: var(--negro);
}
.fondo-2:hover .semillas, .fondo-4:hover .semillas, .fondo-5:hover .semillas{
  color: var(--blanco);
}
.marcas-link{
  color: var(--negro);
  text-decoration: underline;
}
.marcas-link-1{
  color: var(--blanco);
  text-decoration: underline;
}
@media(max-width: 1021px){
  .marca-titulo{
    margin-bottom: 0;
  }
  .color-1, .color-2, .color-3, .color-4, .color-5{
    opacity: 0.9;
  }
  .fondo-1:hover,.fondo-2:hover,.fondo-3:hover, .fondo-4:hover, .fondo-5:hover{
    width: 100%;
  }
  .semillas{
    color: var(--blanco);
  }
  .maquinaria{
    color: var(--negro);
  }
}