.hero{
  height: 100vh;
}
.hero-title{
  color: var(--verde);
  font-size: 24px;
}
.hero-logo{
  width: 100%;
}
.img-hero{
  width: 100%;
}
@media (min-width:1441px){
  .hero{
    height: 100vh;
  }
}
@media (min-width:573px) and (max-width:1022px){
  .img-hero{
    width:60%;
  }
  .hero-logo{
    width:60%;
  }
}
@media (max-width:572px){
  .img-hero{
    width: 75%;
  }
  .hero-logo{
    width: 75%;
  }
}