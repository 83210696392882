.jardineria-card{
  border-bottom: 5px solid var(--naranja);
  width: 30%;
  overflow: hidden;
  height: 475px;
}
.jardineria-card-img{
  width: 280px;
  height:280px;
  object-fit: contain;
}
@media (min-width:573px) and (max-width:1022px){
  .jardineria-card{
    border-bottom: 0;
    border-left: 5px solid var(--naranja);
    width: 48%;
  }
}
@media (max-width:572px){
  .jardineria-card{
    border-bottom: 0;
    border-left: 5px solid var(--naranja);
    width: 100%;
  }
}
