.accordion{
  margin-bottom: 25px;
}
.accordion-item{
  border: none;
}
.accordion-button{
  color: var(--blanco);
  font-size: 45px;
  font-weight: bolder;
  height: 125px;
  margin-bottom: 5px;
  background: linear-gradient(90deg, var(--rojo) 15%, rgba(252,176,69,0) 100%);
}
.accordion-button:not(.collapsed){
  color: var(--blanco);
  font-size: 45px;
  background-color: transparent;
}
.accordion-button:focus{
  box-shadow: none;
}
.accordion-button.accordion-button:not(.collapsed)::after{
  filter: grayscale(100%);
}
.accordion-header{
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}
.gourmet{
  background-image: url('../../assets/Tromen/gourmet.jpg');
}
.calefaccion{
  background-image: url('../../assets/Tromen/calefaccion.jpg');
}
.aire-libre{
  background-image: url('../../assets/Tromen/aire-libre.jpg');
}
.accordion-body{
  padding: 0;
}
@media (min-width:573px) and (max-width:1022px){
  .accordion-button{
    font-size: 37px;
  }
  .accordion-button:not(.collapsed){
    font-size: 37px;
  }
}
@media (max-width:572px){
  .accordion-button{
    font-size: 25px;
    background: linear-gradient(90deg, var(--rojo) 30%, rgba(252,176,69,0) 100%);
    height: 90px;
  }
  .accordion-button:not(.collapsed){
    font-size: 25px;
  }
}